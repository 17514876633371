body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif; */
    font-family: "Roboto Mono", sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #50ffaf; */
    background-color: #f7f6dd;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                 supported by Chrome, Edge, Opera and Firefox */
}

p,
button,
input {
    font-family: "Roboto Mono", sans-serif;
}

code {
    /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace; */
    font-family: "Poppins", sans-serif;
}

.six-digit-input {
    font-size: 20px;
    width: 100%;
    box-sizing: border-box;
    height: 48px;
    border: none;
    display: flex;
    background-color: #fff;
    padding: 0;
    /* padding: 0 26px 0 14px; */
    /* text-indent: 26px; */
    /* direction: rtl; */
    /* padding: 0 5px 0 17px; */
    /* margin-right: -1.2em; */
    letter-spacing: 26px;
    /* box-shadow: 2px 2px 0 0 #000;
    background-color: "#fff";
    border: 1px solid #000;
    border-radius: 8px; */
    border-radius: 8px;
}

textarea:focus,
input:focus {
    outline: none;
}

.fade-in {
    animation: fadeIn 0.5s;
}

.fade-in-long {
    animation: fadeIn 0.75s;
}

.slide-in-from-right {
    animation: slideInFromRight 0.3s ease;
}

.slide-in-from-above {
    animation: slideInFromAbove 0.3s ease;
}

.slide-out-up {
    animation: slideOutOfScreenUp 0.3s ease;
}

/* .slide-in-out {
    animation: slideInAndOut 3s steps(6, end);
} */

.six-digit-input::placeholder {
    font-size: 17px;
    letter-spacing: 29px;
    font-family: "Roboto Mono", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.sliding-bean-baby-dont-ask {
    animation: slideBackBaby 2s ease infinite;
}

#website-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: calc(100vh - 40px);
    overflow: hidden;
}

.rotating-bean-dont-ask {
    animation: rotate 1.2s ease infinite;
}

.rotate-one-eighty {
    transform: rotate(360deg) !important;
}

.open-arrow {
    transition: all 0.3s ease;
    transform: rotate(180deg);
}

#display-none {
    display: none;
}

.cheese-button {
    position: relative;
}

.temp-overflow-hidden {
    animation: tempOverflowHidden 0.8s ease;
}

.top-banner {
    background-color: white;
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    box-shadow: 2px 2px 0 0 #000;
    border: 1px solid;
}

.top-banner-text {
    font-size: 18px;
    margin: 6px;
    font-weight: 500;
}
@keyframes tempOverflowHidden {
    0% {
        overflow: hidden;
    }
    100% {
        overflow: hidden;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes slideBackBaby {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(50px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(-50px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(40%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromAbove {
    0% {
        transform: translateY(-40%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideOutOfScreenUp {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-40%);
    }
}

@keyframes slideInAndOut {
    0% {
        transform: translateY(-40%);
    }
    20% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-40%);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes shootOutRandomOne {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(900px, -500px);
    }
}

@keyframes shootOutRandomTwo {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-900px, 500px);
    }
}

@keyframes shootOutRandomThree {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(1200px, -2000px);
    }
}

@keyframes shootOutRandomFour {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-600px, -500px);
    }
}

@keyframes shootOutRandomFive {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-300px, 500px);
    }
}

@keyframes shootOutRandomSix {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(600px, 800px);
    }
}

@keyframes shootOutRandomSeven {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(900px, 900px);
    }
}

@keyframes shootOutRandomEight {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-500px, -500px);
    }
}

@keyframes shootOutRandomNine {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(900px, 1200px);
    }
}

@keyframes shootOutRandomTen {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(900px, 300px);
    }
}
